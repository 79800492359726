<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" :member="mem" :button3="btn" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Global NDA</h4>
            </div>
            <div class="auth-page-right-content text-center">
              <ThemeButton class="d-block" />
              <h6>Let's verify your Email.</h6>
              <div class="success-img">
                <img src="../../../assets/images/success.png" alt="" />
              </div>
              <p>Please check verification link sent to your email.</p>
            </div>
            <div class="mt-5 pt-2 d-block d-md-none">
              <div class="circle-one d-block d-md-none">
                <img src="../../../assets/images/circle1.png" />
              </div>
              <div class="circle-two d-block d-md-none">
                <img src="../../../assets/images/circle2.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeButton from "../../../components/Enterprise/Theme/ThemeButton.vue";
import AuthLeft from "../../../components/Enterprise/authleft/AuthLeft.vue";

export default {
  name: "EnterpriseVerificationEmail",

  components: {
    ThemeButton,
    AuthLeft,
  },

  data: function() {
    return {
      para: "Simplify your NDA today. Sign up to enjoy the perks!",
      mem: "Already a member?",
      btn: "Sign In"
    };
  },

};
</script>
